import { type Middleware } from "@nuxt/types";
import firebase from "~/plugins/firebase";
import { FirebaseDocuments } from "~/repository";

const authenticated: Middleware = async ({ store, redirect }) => {
  await new Promise<void>((resolve) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      // ログインされていない場合はログイン画面に遷移
      if (!user) {
        return redirect("/login");
      }

      // User storeが存在すればそのままページ遷移
      if (store.state.user.user) {
        return resolve();
      }

      // 画面のリロードなどでstoreがクリアされていれば再度storeにセット
      const { email, uid } = user;

      const adminDocument = new FirebaseDocuments.AdminDocument(uid);
      const data = await adminDocument.getAdminData();
      if (!data) {
        return;
      }
      store.commit("user/setUser", Object.assign(data, { email }));
    });
  });
};

export default authenticated;
