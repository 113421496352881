import type * as firebaseTypes from "~/types/firebase";

export class User {
  static user = {} as firebaseTypes.StateAdmin;

  static setUser(user: firebaseTypes.StateAdmin) {
    this.user = user;
  }

  static fetchUser(): firebaseTypes.StateAdmin {
    return this.user;
  }
}
