import { Collection } from "~/repository/main/collection";
import { Document } from "~/repository/main/document";
import type * as firebaseTypes from "~/types/firebase";

export class AdminCollection extends Collection {
  constructor() {
    super("admin");
  }

  public async getAllAdminData() {
    return await this._getDocumentData();
  }

  public async addAuthData(email: string, password: string, data: firebaseTypes.addAdminData) {
    await this._addAuthData(email, password, data);
  }
}

export class AdminDocument extends Document {
  constructor(adminId: string) {
    super(`admin/${adminId}`);
  }

  public async getAdminData() {
    return await this._getDocumentData();
  }

  public async deleteAdminData() {
    await await this._deleteDocumentData();
  }
}
