import { type MutationTree, type GetterTree } from "vuex";

export const state = () => ({
  sidebar: false,
  systemErrorMessage: null as unknown,
  drawer: true,
});

export type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  toggleSidebar(state) {
    state.sidebar = !state.sidebar;
  },

  setSystemErrorMessage(state, payload: Error) {
    state.systemErrorMessage = payload;
  },
  setDrawer: (state, payload: boolean) => (state.drawer = payload),
};

export const getters: GetterTree<RootState, RootState> = {
  getSystemErrorMessage(state) {
    return state.systemErrorMessage;
  },
};
