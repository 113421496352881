import { Document } from "~/repository/main/document";
import type * as firebaseTypes from "~/types/firebase";

export class PatientDocument extends Document {
  constructor(hospitalId: string, patientId: string) {
    super(`hospital/${hospitalId}/patient/${patientId}`);
  }

  public async getDocumentData() {
    return await this._getDocumentData();
  }

  public async setDocumentData(data: firebaseTypes.PatientDocumentDataSet) {
    await this._setDocumentData(data);
  }
}
