import { Collection } from "~/repository/main/collection";
import { Document } from "~/repository/main/document";
import { type AddHospitalInput, type DisplayFlag, type EditHospitalInput, type HospitalData } from "~/types/hospital";
import { timestampToDisplay } from "~/utils/common";

export class HospitalCollection extends Collection {
  constructor() {
    super("hospital");
  }

  public async addHospitalData(data: AddHospitalInput) {
    return await this._addDocumentData({ ...data, status: "active" });
  }

  public async getHospitalData(): Promise<HospitalData[]> {
    return timestampToDisplay(await this._getDocumentData()) as HospitalData[];
  }
}

export class HospitalDocument extends Document {
  constructor(hospitalId: string) {
    super(`hospital/${hospitalId}`);
  }

  public async insertFlag(
    departmentId: string,
    oldDisplayFlag: Record<
      string,
      {
        questionsForDoctorDescription: boolean;
        questionsForDoctorSelective: boolean;
      }
    >
  ) {
    return await this._updateDocumentData({
      screenDisplay: {
        ...oldDisplayFlag,
        [departmentId]: {
          questionsForDoctorDescription: false,
          questionsForDoctorSelective: false,
        },
      },
    });
  }

  public async updateDisplayFlagData({
    value,
    departmentId,
    flag,
  }: {
    value: boolean;
    departmentId: string;
    flag: DisplayFlag;
  }) {
    return await this._updateDocumentData({
      [`screenDisplay.${departmentId}.${flag}`]: value,
    });
  }

  public async updateQuestionsData({ questions, departmentId }: { questions: string[]; departmentId: string }) {
    if (questions.length === 0) {
      return await this._updateDocumentData({
        [`questions.${departmentId}`]: [],
      });
    }
    return await this._updateDocumentData({
      [`questions.${departmentId}`]: questions,
    });
  }

  public async getHospitalData(): Promise<HospitalData> {
    return timestampToDisplay([(await this._getDocumentData())!])[0] as HospitalData;
  }

  public async updateHospitalData(input: EditHospitalInput) {
    return await this._updateDocumentData({ name: { ja: input.name.ja } });
  }

  public async updateTestFlag(isTest: boolean) {
    return await this._updateDocumentData({ isTest });
  }

  public async updateConsentRole(role: boolean) {
    return await this._setDocumentData({ role: { consent: role } });
  }

  public async updatePatientVideoOnStaffDeviceRole(role: boolean) {
    return await this._setDocumentData({
      role: { patientVideoOnStaffDevice: role },
    });
  }

  public async updateVideoRole(role: boolean) {
    return await this._setDocumentData({
      role: { video: role },
    });
  }

  public async updateMedicalInterviewRole(role: boolean) {
    return await this._setDocumentData({
      role: { medicalInterview: role },
    });
  }

  public async updateTobaccoCessationClinicFlow(role: boolean) {
    return await this._setDocumentData({
      role: { tobaccoCessationClinicFlow: role },
    });
  }

  public async deleteHospitalData() {
    await this._updateDocumentStatusDeleted();
  }

  public async archiveHospitalData() {
    return await this._setDocumentData({ status: "archived" });
  }

  public async activateHospitalData() {
    return await this._setDocumentData({ status: "active" });
  }
}
