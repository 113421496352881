/* eslint-disable import/no-duplicates */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const config = {
  apiKey: "AIzaSyBay0IjT-qsIM8fDoLr9uvi_-OYinnU3fo",
  authDomain: "contrea-dev.firebaseapp.com",
  databaseURL: "https://contrea-dev.firebaseio.com",
  projectId: "contrea-dev",
  storageBucket: "contrea-dev.appspot.com",
  messagingSenderId: "807273632873",
  appId: "1:807273632873:web:c74f6efafa55caeea7fef8",
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export default firebase;
