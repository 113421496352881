import { User } from "./user";
import { commonDocumentData } from "../lib";
import firebase from "~/plugins/firebase";
import type * as firebaseTypes from "~/types/firebase";

const db = firebase.firestore();

export class Collection {
  protected ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  protected logRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(path: string) {
    this.ref = db.collection(path);
    this.logRef = db.collection("event_admin");
  }

  async createEventLogData(eventType: string, path: string, data: object) {
    const user = User.fetchUser();

    await this.logRef.add({
      data,
      ...commonDocumentData(),
      eventType,
      user,
      path,
    });
  }

  protected async _addDocumentData(data: object): Promise<void> {
    const newData = {
      ...data,
      ...commonDocumentData(),
    };
    const qs = await this.ref.add(newData);
    await this.createEventLogData("addDocument", qs.path, newData);
  }

  protected async _addAuthData(email: string, password: string, data: object): Promise<string> {
    const newData = {
      ...data,
      ...commonDocumentData(),
    };

    const authResult = await firebase.auth().createUserWithEmailAndPassword(email, password);
    const uid = authResult.user!.uid;
    await this.ref.doc(uid).set(newData);
    await this.createEventLogData("addAuthData", this.ref.path + "/" + uid, newData);
    return uid;
  }

  protected async _getDocumentData(): Promise<firebaseTypes.DocumentDataWithId[]> {
    const qs = await this.ref.orderBy("updateTimestamp", "desc").get();
    return qs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  }
}
