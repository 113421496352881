import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from "vee-validate";
import { required, min, min_value, max, excluded, email, numeric } from "vee-validate/dist/rules";
import Vue from "vue";

setInteractionMode("eager");

extend("email", {
  ...email,
  message: "メールアドレスの形式が不正です",
});

extend("required", {
  ...required,
  message: "この項目は必須です",
});

extend("required_rating", {
  ...min_value,
  message: "選択してください",
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "パスワードが一致していません",
});

extend("min", {
  ...min,
  message: "{length}文字以上で入力してください",
});

extend("max", {
  ...max,
  message: "{length}文字以内で入力してください",
});

extend("numeric", {
  ...numeric,
  message: "数値を入力してください",
});

extend("min_value", {
  ...min_value,
  message: "{min}以上の数値を入力してください",
});

extend("excluded", {
  ...excluded,
  message: "その名前は既に使用されています",
});

const regex = /^[0-9a-zA-Z]*$/;
extend("alpha_num", {
  validate: (val) => {
    return val.match(regex);
  },
  message: "半角英数字で入力してください",
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
