import firebase from "~/plugins/firebase";
import type * as firebaseTypes from "~/types/firebase";

export const commonDocumentData = (): firebaseTypes.CommonDocumentData => {
  return {
    addTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
    updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
  };
};

export const commonDocumentDataSet = (): firebaseTypes.CommonDocumentDataSet => {
  return {
    updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
  };
};
