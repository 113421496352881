import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#008DA3",
        secondary: "#4caf50",
        tertiary: "#495057",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});

Vue.use(vuetify);

// export default (ctx) => {
//   // const vuetify = new Vuetify({
//   //   theme: {
//   //     themes: {
//   //       light: {
//   //         primary: "#008DA3",
//   //         secondary: "#4caf50",
//   //         tertiary: "#495057",
//   //       },
//   //     },
//   //   },
//   //   icons: {
//   //     iconfont: "mdi",
//   //   },
//   // });
//   // ctx.app.vuetify = vuetify;
//   // ctx.$vuetify = vuetify.framework;
// };
