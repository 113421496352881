import dayjs from "dayjs";
import firebase from "~/plugins/firebase";
import type * as firebaseTypes from "~/types/firebase";

const fixDigit = (num: number) => {
  if (num < 10) {
    return "0" + num.toString();
  } else {
    return num;
  }
};

export const timestampToDisplay = <T extends firebaseTypes.DocumentDataWithId[]>(data: T): T => {
  for (const tmp of data) {
    tmp.addTimestamp = getDate(tmp.addTimestamp);
    tmp.updateTimestamp = getDate(tmp.updateTimestamp);
  }
  return data;
};

export const getDate = (timestamp: firebase.firestore.Timestamp) => {
  const date = timestamp.toDate();
  const YYYY = date.getFullYear();
  const mm = fixDigit(date.getMonth() + 1);
  const dd = fixDigit(date.getDate());
  const HH = fixDigit(date.getHours());
  const ii = fixDigit(date.getMinutes());
  const ss = fixDigit(date.getSeconds());
  return `${YYYY}/${mm}/${dd} ${HH}:${ii}:${ss}`;
};

export const isEmptyObj = (obj: object): boolean => {
  return obj ? false : Object.keys(obj).length === 0;
};

// これ使ったほうがよさそう、、、
export const timestampToDate = (timestampObj: firebase.firestore.Timestamp): Date => {
  return timestampObj.toDate();
};

export const getSelectOptionArray = (docs: firebaseTypes.DocumentDataWithId[]): firebaseTypes.SelectOption[] => {
  docs.sort(function (a, b) {
    if (a.updateTimestamp < b.updateTimestamp) {
      return 1;
    }
    if (a.updateTimestamp > b.updateTimestamp) {
      return -1;
    }
    return 0;
  });
  return docs.map((doc) => ({ value: doc.id, body: doc.name.ja }));
};

export const fetchStorageUrl = async (
  departmentId: string,
  organId: string,
  approachId: string,
  genreId: string,
  bucketName: string,
  isLocal: boolean
) => {
  const storageDefault = firebase.storage();
  const storageVideo = isLocal ? storageDefault : firebase.app().storage(`gs://${bucketName}`);

  const storageRefThumbnail = await storageDefault.ref(
    `department/${departmentId}/organ/${organId}/approach/${approachId}/genre/${genreId}`
  );
  const storageRefVideo = await storageVideo.ref(
    `department/${departmentId}/organ/${organId}/approach/${approachId}/genre/${genreId}`
  );

  return {
    storageRefThumbnail,
    storageRefVideo,
  };
};

export const fetchV2StorageUrl = async (videoId: string, bucketName: string, isLocal: boolean) => {
  const storageDefault = firebase.storage();
  const storageVideo = isLocal ? storageDefault : firebase.app().storage(`gs://${bucketName}`);

  const storageRefThumbnail = await storageDefault.ref(`video/${videoId}`);
  const storageRefVideo = await storageVideo.ref(`video/${videoId}`);

  return {
    storageRefThumbnail,
    storageRefVideo,
  };
};

export const formatDate = (date: Date) => {
  return dayjs(date.getTime()).format(`YYYY-MM-DD`);
};

export const getFileObj = (val: File | FileList) => {
  return val instanceof File ? (val as File) : (val as FileList)[0];
};

export const hasExtension = (val: string) => {
  return val.lastIndexOf(".") > 0;
};
