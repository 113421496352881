import { User } from "./user";
import { commonDocumentData, commonDocumentDataSet } from "../lib";
import firebase from "~/plugins/firebase";
import type * as firebaseTypes from "~/types/firebase";

const db = firebase.firestore();

export class Document {
  protected ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  protected logRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(path: string) {
    this.ref = db.doc(path);
    this.logRef = db.collection("event_admin");
  }

  async createEventLogData(eventType: string): Promise<void> {
    const data = await this._getDocumentData();
    const user = User.fetchUser();
    await this.logRef.add({
      originalData: data ?? {},
      ...commonDocumentData(),
      eventType,
      user,
      path: this.ref.path,
    });
  }

  protected async _addDocumentData(data: object): Promise<void> {
    await this.ref.set({
      ...data,
      ...commonDocumentDataSet(),
    });

    await this.createEventLogData("addDocument");
  }

  /**
   * @deprecated _setDocumentDataを用いるようにする
   */
  protected async _updateDocumentData(data: object): Promise<void> {
    await this.createEventLogData("updateDocument");
    return this.ref.update({
      ...data,
      ...commonDocumentDataSet(),
    });
  }

  /**
   * @deprecated 論理削除にする。
   */
  protected async _deleteDocumentData() {
    await this.createEventLogData("deleteDocument");
    await this.ref.delete();
  }

  protected async _updateDocumentStatusDeleted() {
    return await this._setDocumentData({
      status: "deleted",
    });
  }

  protected async _getDocumentData(): Promise<firebaseTypes.DocumentDataWithId | undefined> {
    const querySnapshot = await this.ref.get();
    const data = querySnapshot.data();
    const id = querySnapshot.id;
    if (data === undefined) {
      return undefined;
    }
    return {
      ...data,
      id,
    };
  }

  protected async _setDocumentData(data: object, isMerge: boolean = true): Promise<void> {
    await this.createEventLogData("setDocument");
    await this.ref.set(
      {
        ...data,
        ...commonDocumentDataSet(),
      },
      { merge: isMerge }
    );
  }
}
