import { type Middleware, type NuxtAppOptions } from "@nuxt/types";

// master になっているなら何もしない、masterでなければルートに遷移
const master: Middleware = ({ store, redirect }: NuxtAppOptions) => {
  if (store?.state.user.user.role !== "master") {
    window.alert("このページはmaster権限でログインしている必要があります");
    return redirect("/");
  }
};

export default master;
