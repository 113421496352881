import { AdminCollection, AdminDocument } from "~/repository/components/admin";
import { DoctorCollection } from "~/repository/components/doctor";
import { HospitalCollection, HospitalDocument } from "~/repository/components/hospital";
import { NotificationMessageCollection, NotificationTargetCollection } from "~/repository/components/notification";
import { PatientDocument } from "~/repository/components/patient";

export const FirebaseCollections = {
  AdminCollection,
  DoctorCollection,
  HospitalCollection,
  NotificationMessageCollection,
  NotificationTargetCollection,
};

export const FirebaseDocuments = {
  AdminDocument,
  HospitalDocument,
  PatientDocument,
};
