import { type MutationTree } from "vuex";
import { type StateAdmin } from "../../types/firebase";

export const state = () => ({
  user: {} as StateAdmin,
});

export type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  setUser(state, payload: StateAdmin) {
    state.user = payload;
  },

  logout(state) {
    state.user = {} as StateAdmin;
  },
};
