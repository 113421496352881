import { Collection } from "~/repository/main/collection";
import { type DoctorData } from "~/types/doctor";

export class DoctorCollection extends Collection {
  constructor(hospitalId: string) {
    super(`hospital/${hospitalId}/staff`);
  }

  public async getDoctorData(): Promise<DoctorData[]> {
    return (await this._getDocumentData()) as DoctorData[];
  }

  public async addDoctorData(email: string, password: string, data: any): Promise<string> {
    return await this._addAuthData(email, password, data);
  }
}
